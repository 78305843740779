  .containerLogin{
    width: 100%;
    min-width: 1100px;
    height: 100vh;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background: #fff;
    padding: 20px;
  }

  .button {
    flex: 1;
    border-radius: 5px;
    min-height: 32px !important;
    max-height: 32px !important;
    width: 350px;
    color: #fff !important;
    border: none !important;
    background: #72B3FF;
  }

  .password {
    margin-top: 10px;
    font-size: 13px;
    color: #999999;
    cursor: pointer;
  }

  .contentLoginV{
    flex: 1;
    display: flex;
    width: 50%;
    height: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 15px;
  }

  .contentLogin{
    width: 350px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-end;
  }

  .logo {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0px 0px 30px 0;
  }

  .input{
    width: 350px;
    margin: 20px 0 25px 0; 
    padding: 10px 10px;
    background: #FDFDFD;
    border-color: #9B9B9B;
    border-width: 1px;
    border-style: solid;
    border-radius: 5px;
  }
