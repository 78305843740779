  .container{
    width: 100%;
    min-width: 1620px;
    min-height: 900px !important;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: #fff;
    padding: 20px;
  }

  .row{
    width: 100%;
    flex: 1;
    min-width: 1560px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .contentEdit {
    flex: 1;
    display: flex;
    max-width: 49%;
    flex-direction: column;
    border-radius: 15px;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0 0 25px 0 !important;
    position: relative;
  }


  .contentEdit button{
    flex: 1;
    margin-top: 20px;
    border-radius: 5px;
    max-height: 30px;
    width: 130px;
    font-size: 14px;
    font-weight: bold;
    color: #fff;
    background: #72B3FF;
  }
  .contentFiles{
    width: 100%;
    display: flex;
    border-radius: 15px;
    flex-direction: column;
    background: #EFEFEF;
    padding: 25px;
  }

  .header {
    display: flex;
    width: 100%;
    max-height: 100px;
    padding: 0 50px;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    border-radius: 15px;
    margin-bottom: 20px;
    background: #EFEFEF;
  }

  .header img{
    width: 300px;
    height: 90px;
    object-fit: contain;
    margin: 0;
  }

  .header p{
    font-weight: bold;
    font-size: 14px;
    color: #707070;
    cursor: pointer;
  }

  .campanhaHome {
    display: flex;
    width: 100%;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    border-bottom-width: 1px;
    border-bottom-style: solid;
    border-bottom-color: #fff;
    padding: 10px 10px;
    background: #EFEFEF;
  }

  .buttonBorder{
    padding: 5px 15px;
    border-radius: 5px;
    border-width: 1px;
    display: flex;
    align-items: center;
    border-style: solid;
    background: #FDFDFD;
    color: #717171 !important;
    border-color: #717171 !important;
    border-width: 1px !important;
  }

  .campanhaHome p{
    font-weight: bold;
    font-size: 14px;
    color: #717171 !important;
    cursor: pointer;
  }

  .active {
    border-width: 1px !important;
    border-color: #58CB53 !important;
    color: #58CB53 !important;
    width: 105px;
  }
  .inative {
    border-width: 1px !important;
    border-color: #EF534F !important;
    color: #EF534F !important;
    width: 105px;
  }

  .campanhaHome .buttonHome{
    color: #717171 !important;
    border-color: #717171 !important;
    border-width: 1px !important;
  }

  .title{
    font-weight: bold;
    font-size: 25px;
    color: #72B3FF;
    margin-bottom: 25px;
  }

  .rowFiles {
    display: flex;
    width: 100%;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
  }
  
  .dragging {
    background-color: #eee;
    border-color: #555;
  }
  
  .files {
    border-radius: 12px;
    background-color: #fff !important;
    padding: 20px 50px;
    height: 140px;
    width: 49%;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    position: relative;
  }
  
  .files p {
    flex: 1;
    font-size: 14px;
    text-align: left;
  }
  
  .fileInput {
    position: absolute;
    left: 0;
    height: 100%;
    width: 100%;
    cursor: pointer;
  }
  
  .fileInput input {
    display: none;
  }

  .pagination{
    width: calc(100% - 40px);
    position: absolute;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    bottom: 25px;
    align-items: center;
  }

  .pagination button{
    border-radius: 5px;
    min-height: 28px;
    max-height: 28px;
    width: 90px;
    padding-top: 2px;
    font-size: 12px;
    font-weight: bold;
    color: #fff;
    background: #72B3FF;
    margin: 0 15px;
  }

  .pagination p{
    margin: 0 25px;
    font-size: 14px;
    font-weight: bold;
    color: #777
  }

  .star{
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 30px;
  }
  .star img{
    position: absolute;
    top: -60px;
    width: 80px;
    height: 80px;
  }

  .uploadInfos{
    width: 93%;
    border-bottom-width: 1px;
    border-bottom-style: solid;
    border-bottom-color: #f0f0f0;
    margin-left: 4%;
    padding: 35px 10px 10px 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 15px;
    color: #707070;
  }

  .uploadInfos h4{
    font-size: 12px;
    margin-bottom: -4px;
    margin-left: 20px;
    cursor: pointer;
  }

  .uploadInfos h4:hover{
    color: #000;
  }

  .uploadInfos span{
    font-weight: bold;
  }

  .uploadInfos p{
    flex: 1;
    text-align: right;
  }

  .details{
    width: 100%;
    height: 400px;
    overflow-y: auto;
  }

  .details .detailsImg{
    width: 100%;
    border-bottom-width: 1px;
    border-bottom-style: solid;
    border-bottom-color: #f0f0f0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    font-size: 15px;
    color: #707070;
  }

  .details .detailsImg p{
    width: 48%;
  }