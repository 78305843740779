  .container{
    width: 100%;
    min-width: 1620px;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: #fff;
    padding: 20px;
  }

  .row{
    width: 100%;
    flex: 1;
    min-width: 1560px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .button {
    flex: 1;
    margin-top: 20px;
    border-radius: 5px;
    min-height: 30px;
    max-height: 30px;
    width: 130px;
    font-size: 14px;
    font-weight: bold;
    color: #fff;
    background: #72B3FF;
  }

  .content {
    flex: 1;
    display: flex;
    max-width: calc(50% - 10px);
    flex-direction: column;
    border-radius: 15px;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 25px 20px;
    position: relative;
  }

  .content button{
    padding: 8px 10px;
    border-radius: 5px;
    border-width: 1px;
    display: flex;
    align-items: center;
    border-style: solid;
    background: #FDFDFD;
    border-width: 1px !important;
  }

  .buttonExport{
    color: #717171 !important;
    border-color: #717171 !important;
  }

  .contentFiles{
    width: 100%;
    display: flex;
    border-radius: 15px;
    flex-direction: column;
    background: #EFEFEF;
    padding: 25px;
  }

  .header {
    display: flex;
    width: 100%;
    max-height: 100px;
    min-width: 1560px;
    padding: 0 50px;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    border-radius: 15px;
    margin-bottom: 20px;
    background: #EFEFEF;
  }

  .header img{
    width: 300px;
    height: 90px;
    object-fit: contain;
    margin: 0;
  }

  .header p{
    font-weight: bold;
    font-size: 14px;
    color: #707070;
    cursor: pointer;
  }

  .campanha {
    display: flex;
    width: 100%;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    border-bottom-width: 1px;
    border-bottom-style: solid;
    border-bottom-color: #fff;
    padding: 0;
    background: #EFEFEF;
  }

  .campanha button{
    padding: 8px 10px;
    border-radius: 5px;
    border-width: 1px;
    display: flex;
    align-items: center;
    border-style: solid;
    border-color: #717171;
    background: #FDFDFD;
    color: #717171;
  }

  .campanha p{
    border-left-width: 1px;
    border-left-style: solid;
    border-left-color: #fff;
    font-weight: bold;
    font-size: 14px;
    color: #555;
    padding: 10px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    cursor: pointer;
  }

  .chart{
    display: flex;
    flex-direction: column;
    width: 32%;
    align-items: flex-start;
    height: 100%;  
  }

  .chart p{
    color: #707070;
    width: 100%;
    font-size: 13px;
    text-align: left;
    border-radius: 15px;
  }

  .chart span{
    color: #707070;
    width: 100%;
    font-size: 13px;
    text-align: left;
    border-radius: 15px;
  }
  
  .chart h1{
    color: #707070;
    margin: 10px 0 0 0;
    width: 100%;
    font-size: 60px;
    text-align: left;
    border-radius: 15px;
    font-weight: bold;
  }

  .chart div{
    background: #FDFDFD;
    padding: 15px;
    width: 100%;
    border-radius: 10px;
    margin-bottom: 15px;
  }

  .title{
    font-weight: bold;
    font-size: 25px;
    color: #72B3FF;
    margin-bottom: 25px;
  }
  
  .pagination{
    width: calc(100% - 40px);
    position: absolute;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    bottom: 25px;
    align-items: center;
  }

  .pagination button{
    border-radius: 5px;
    min-height: 28px;
    max-height: 28px;
    width: 90px;
    padding-top: 10px;
    font-size: 12px;
    font-weight: bold;
    color: #fff;
    background: #72B3FF;
    margin: 0 15px;
    border: none;
    display: flex;
    justify-content: center;
  }

  .pagination p{
    margin: 0 25px;
    font-size: 14px;
    font-weight: bold;
    color: #777
  }

  .graphs {
    display: flex;
    width: 150px;
    padding: 25px 10px;
    flex-direction: row;
    justify-content: center;
    border-left-width: 1px;
    border-left-style: solid;
    border-left-color: #fff;
    height: 100%;
  }

  .graph {
    display: flex;
    width: 40px;
    flex-direction: column;
    height: 120px;
    justify-content: flex-end;
    align-items: center;
  }

  .graph p{
    width: 100%;
    height: 30px;
    border-radius: 4px;
    font-size: 12px;
    margin-top: 10px;
    padding: 0;
    text-align: center;
    border: none;
    font-weight: bold;
  }
  .graph div{
    width: 30px;
    height: 80px;
    background-color: #fff;
    border-radius: 4px;
  }