  .containerCampanha{
    width: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: #fff;
    padding: 15px;
  }

  .button {
    flex: 1;
    margin-top: 20px;
    border-radius: 5px;
    min-height: 40px;
    max-height: 40px;
    width: 100%;
    font-size: 14px;
    font-weight: bold;
    color: #fff;
    background: #72B3FF;
  }

  .contentBorder {
    display: flex;
    flex: 1;
    width: 100%;
    max-width: 420px;
    border-width: 2px;
    border-color: #EFEFEF !important;
    border-style: solid;
  }

  .contentCampanha {
    display: flex;
    flex: 1;
    width: 100%;
    max-width: 420px;
    flex-direction: column;
    border-radius: 15px;
    justify-content: flex-start;
    align-items: center;
    padding: 25px;
    background: #EFEFEF;
    position: relative;
  }

  .contentForm {
    display: flex;
    width: 100%;
    min-height: 100vh;
    max-width: 420px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }

  .contentForm div{
    display: flex;
    width: calc(100% - 30px);
    height: auto;
    min-height: 200px;
    border-radius: 15px;;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    background: #EFEFEF;
    margin: 15px;
    padding: 8px 20px 20px 20px;
  }

  .contentForm span{
    color: #707070;
    width: 100%;
    font-size: 15px;
    text-align: left;
    font-weight: bold;
    display: flex;
    margin-top: 12px;
    padding-bottom: 10px;
    align-items: center;
  }

  .contentForm p{
    color: #707070;
    width: 100%;
    font-size: 13px;
    text-align: left;
    display: flex;
    margin: 5px 0 10px 0 !important;
    align-items: center;
  }
  
  .input{
    width: 100%;
    margin: 5px 0; 
    padding: 10px 10px;
    background: #FDFDFD;
    border-color: #9B9B9B;
    border-width: 1px;
    border-style: solid;
    border-radius: 5px;
  }

  .input input{
    width: 100%;
  }

  .logoCampanha{
    height: 90px;
    object-fit: contain;
    margin: 100px 0 80px 0;
  }

  .title{
    font-weight: bold;
    font-size: 25px;
    color: #72B3FF;
    margin-bottom: 25px;
  }

  .sliderImage{
    width: 100%;
    height: 70vh;
  }

  .rowForm {
    display: flex;
    width: 100% !important;
    margin: 0px 0px 0px 0px !important;
    padding: 0px 0px 0px 0px !important;
    align-items: center;
    flex-wrap: wrap;
    height: 40px !important;
    min-height: auto !important;
    flex-direction: row !important;
    justify-content: flex-start !important;
  }

  .rowForm button{
    padding: 6px 10px 6px 8px;
    border-radius: 5px;
    border-width: 1px;
    display: flex;
    align-items: center;
    border-style: solid;
    border-color: #717171;
    background: #FDFDFD;
    color: #717171;
    margin-right: 10PX;
  }

  .rowCores{
    display: flex;
    width: 100% !important;
    min-height: 185px !important;
    margin: 0px 0px 0px 0px !important;
    padding: 0px 0px 0px 0px !important;
    align-items: center;
    flex-wrap: wrap;
    min-height: auto !important;
    flex-direction: row !important;
    justify-content: flex-END !important;
  }

  .cor{
    width: 33% !important;
    height: 30px !important;
    min-height: auto !important;
    margin: 0px 0px 0px 0px !important;
    padding: 0px 0px 0px 0px !important;
    justify-content: flex-start !important;
    align-items: flex-start !important;
  }

  .cor span{
    margin: 0px;
    font-size: 11px;
    color: #717171;
  }

  .rowCores button{
    border-radius: 5px;
    min-height: 28px;
    max-height: 28px;
    width: 90px;
    padding-top: 2px;
    font-size: 12px;
    font-weight: bold;
    color: #fff;
    background: #72B3FF;
    margin-top: 10px;
  }


  .rowFormButton{
    color: #707070 !important;
    font-size: 14px;
    border-color: #9B9B9B !important;
    border-width: 1px !important;
  }

  .sucess{
    width: 90%;
    background-color: #FFF;
    border-radius: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #58CB53;
    font-weight: bold;
    position: relative;
    padding: 45px 0 35px 0;
    font-size: 28px;
    margin-top: 40px;
  }

  .sucess img{
    position: absolute;
    top: -30px;
    width: 60px;
    height: 60px;
  }

  .sucess p{
    text-align: center;
    width: 80%;
  }

  .carousel {
    border-color: #000 !important; 
    padding: 0 !important;
    margin: 0 !important;
    width: 100% !important;
    border-radius: 0 !important;
  }

  .carousel .control-arrow {
    opacity: 1 !important;
  }

  .carousel .control-arrow:hover {
    background-color: transparent !important;
  }

  .carousel .control-prev.control-arrow:before{
    border-right: 12px solid #000 !important;
    border-top: 12px solid transparent !important;
    border-bottom: 12px solid transparent !important;
  }
  .carousel .control-next.control-arrow:before{
    border-left: 12px solid #000 !important;
    border-top: 12px solid transparent !important;
    border-bottom: 12px solid transparent !important;
  }

  .carousel div{
    padding: 0 !important;
    margin: 0 !important;
    width: 100% !important;
    border-radius: 0 !important;
  }


  .titleEnd{
    font-size: 22px;
    text-transform: uppercase;
    text-align: center;
    width: 80%;
    color: #707070;
    line-height: 30px;
    font-weight: bold;
  }